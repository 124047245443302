import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { CountriesService, LanguageService } from '@shared/services';

@Component({
  selector: 'rp-home-root',
  standalone: true,
  template: `
    <div class="app-wrapper">
      <router-outlet />
    </div>
  `,
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private readonly _languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);
  private readonly _countriesService = inject(CountriesService);

  ngOnInit(): void {
    this._setLanguage();
  }

  private _setLanguage(): void {
    const currentLanguage = this._languageService.getCurrentLanguage();

    this._translateService.use(currentLanguage);
    this._countriesService.registerLocale(currentLanguage);
  }
}
