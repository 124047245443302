import { Route } from '@angular/router';

import { ROUTES } from '@shared/models';
import { ShellComponent } from '@shared/components';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () => import('@features/landing').then(m => m.LandingComponent),
    pathMatch: 'full',
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: ROUTES.signUp,
        loadComponent: () => import('@features/sign-up').then(m => m.SignUpComponent),
      },
      {
        path: ROUTES.verifyEmail,
        loadComponent: () => import('@features/sign-up').then(m => m.VerifyEmailComponent),
      },
      {
        path: ROUTES.signUpWebmaster,
        loadComponent: () => import('@features/sign-up').then(m => m.WebmasterComponent),
      },
      {
        path: ROUTES.signUpAdvertiser,
        loadComponent: () => import('@features/sign-up').then(m => m.AdvertiserComponent),
      },
      {
        path: ROUTES.auth,
        loadComponent: () => import('@features/auth').then(m => m.AuthComponent),
        children: [
          {
            path: ROUTES.createPassword,
            loadComponent: () => import('@features/auth').then(m => m.CreatePasswordComponent),
          },
          {
            path: ROUTES.forgotPassword,
            loadComponent: () => import('@features/auth').then(m => m.ForgotPasswordComponent),
          },
          {
            path: ROUTES.signIn,
            loadComponent: () => import('@features/auth').then(m => m.SignInComponent),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('@features/not-found').then(m => m.NotFoundComponent),
  },
];
